import { Loader } from "@googlemaps/js-api-loader"
const apiKey = 'AIzaSyBlmJyazzgH4T_3lgPDHPE9bhQAu0_0otM'

let loaded = false

const loader = new Loader({
  apiKey,
  version: "weekly",
  id: 'main'
})

export async function loadGoogleMaps (): Promise<boolean> {
  if (!loaded) {
    await loader.load()
    loaded = true
  }
  return loaded
}
