
































import Vue from "vue";
import { Prop, Component } from 'vue-property-decorator'

@Component
export default class MapEventInfoWindow extends Vue {

  @Prop({ required: true })
  readonly org

  @Prop({ required: true })
  readonly locIndex

  private get loc () {
    if (this.allLocations.length >= this.locIndex) {
      return this.allLocations[this.locIndex]
    } else {
      return null
    }
  }

  private get allLocations () {
    if (this.org.locations != null) {
      return this.org.locations
    } else if (this.org.location != null){
      return [this.org.location]
    } else {
      return []
    }
  }
}

