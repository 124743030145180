




















































import Vue from "vue";
import { Prop, Component } from 'vue-property-decorator'
import { formatDateRangeDisplay } from '../directives/DateDisplay'

@Component
export default class MapEventInfoWindow extends Vue {

  @Prop({ required: true })
  readonly events

  private eventIndex = 0

  private get dates () {
    return formatDateRangeDisplay(this.currentEvent.date, this.currentEvent.endDate)
  }

  private get currentEvent () {
    return this.events[this.eventIndex]
  }

  private prevEvent () {
    if (this.eventIndex === 0) {
      this.eventIndex = (this.events.length - 1)
    } else {
      this.eventIndex--
    }
  }

  private nextEvent () {
    if (this.eventIndex === (this.events.length - 1)) {
      this.eventIndex = 0
    } else {
      this.eventIndex++
    }
  }
}

