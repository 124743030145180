

























































/* eslint-disable no-undef */
// Above rule disabled as 'google.maps' types have issues loading
import EventsDisplay from '../components/EventsDisplay.vue'
import MapEventInfoWindow from './MapEventInfoWindow.vue'
import MapOrgInfoWindow from './MapOrgInfoWindow.vue'
import Vue from "vue";
import debounce from 'lodash/debounce'
import { Watch, Component } from 'vue-property-decorator'
import { getCurrentPosition } from '../util/Geolocation'
import { getMapIcon } from '../model/EventIcons'
import { loadGoogleMaps } from '../util/GoogleMaps'
import { mapState } from 'vuex'

const TYPE_EVENTS = 0
const TYPE_ORGS = 1

let markerOverlay

@Component({
  components: {
    EventsDisplay
  },
  computed: {
    ...mapState([
      'activeTab',
      'mapEvents',
      'mapOrgs',
    ])
  }
})
export default class MapView extends Vue {

  private typeToggle = 0

  private loading = false

  private renderedMarkers = new Map()
  private eventsByLoc = new Map()

  private map: google.maps.Map | null = null

  private mapEvents
  private mapOrgs
  private currentInfoWindow

  private showSearchButton = false

  private position: GeolocationPosition | null = null

  private windowHeight = 200

  private get mapHeight () {
    return this.windowHeight - 120
  }

  private async mounted () {
    this.windowHeight = window.innerHeight
    window.addEventListener('resize', () => {
      this.windowHeight = window.innerHeight
    })

    await loadGoogleMaps()

    // get center and zoom from route, if defined
    const query = this.$route.query
    let lat, lng, zoom
    if (typeof query.lat === 'string') {
      lat = parseFloat(query.lat)
    }
    if (typeof query.lng === 'string') {
      lng = parseFloat(query.lng)
    }
    if (typeof query.zoom === 'string') {
      zoom = parseInt(query.zoom)
    }

    this.map = new google.maps.Map(this.$refs.map as HTMLElement, {
      center: {
        lat: lat || 39.9526,
        lng: lng || -75.1625,
      },
      zoom: zoom || 8,
      fullscreenControl: false,
      gestureHandling: 'greedy'
    })

    const getCurrentType = () => {
      return this.typeToggle
    }

    markerOverlay = new google.maps.OverlayView()
    markerOverlay.draw = function () {
      const panes = this.getPanes()
      if (panes == null) {
        return
      }
      if (getCurrentType() === TYPE_EVENTS) {
        panes.markerLayer.id = 'markerLayer'
      } else {
        panes.markerLayer.id = 'markerLayer-with-borders'
      }
    }
    markerOverlay.setMap(this.map)

    const isSpecificArea = (query.lat != null || query.lng != null)
    if (isSpecificArea) {
      // If the map is at a specific location, load the current position
      // but do not wait for it, and do not center on it
      this.loadCurrentPosition(false)
    } else {
      // Otherwise, wait for the users location, and center on it, and execute
      // a new query
      this.loadCurrentPosition(true)
    }

    // Listen for bounds changes and execute initial search
    setTimeout(() => {
      if (this.map != null) {
        this.map.addListener('bounds_changed', debounce(this.onBoundsChanged, 500))
        this.search()
      }
    }, 1000)
  }

  private onBoundsChanged () {
    this.showSearchButton = true

    if (this.map == null) {
      return
    }

    const zoom = this.map.getZoom()
    const coords = this.map.getCenter()
    if (zoom == null || coords == null) {
      return
    }

    this.$router.replace({
      query: {
        zoom: zoom.toString(),
        lat: coords.lat().toString(),
        lng: coords.lng().toString()
      }
    })

    // Instead of showing search button, automatically perform the search.
    // Remove this if showing the search button instead.
    this.search()
  }

  private async loadCurrentPosition (center: boolean) {
    if (this.map == null) {
      return
    }

    // Get position of the user
    this.position = await getCurrentPosition()
    if (this.position == null) {
      return
    }

    // Center on that position if provided
    if (center) {
      const lat = this.position.coords.latitude
      const lng = this.position.coords.longitude
      this.map.setCenter({
        lat,
        lng
      })
      this.search()
    }
  }

  private async search () {
    this.showSearchButton = false
    this.loading = true
    if (this.map == null) {
      return
    }
    const bounds = this.map.getBounds()
    const points = this.convertGoogleBoundsToAlgolia(bounds)
    if (this.typeToggle === TYPE_EVENTS) {
      await this.$store.dispatch('geosearchMapEvents', points)
    } else if (this.typeToggle === TYPE_ORGS) {
      await this.$store.dispatch('geosearchMapOrgs', points)
    }
    this.loading = false
  }

  private convertGoogleBoundsToAlgolia (bounds) {
    const ne = bounds.getNorthEast()
    const sw = bounds.getSouthWest()
    return [
      ne.lat(),
      ne.lng(),
      sw.lat(),
      sw.lng()
    ]
  }

  private clearMarkers () {
    this.renderedMarkers.forEach(marker => {
      marker.setMap(null)
    })
    this.renderedMarkers.clear()
  }

  private locKey(geoloc) {
    // uniquely identify a location by combining its coordinates
    // in string form. does not rely on float comparison, but does
    // assume all locations instances for the same logical location
    // are geocoded the same way (a safe assumption given our CMS logic).
    //
    // still, this is worst key I've ever written.
    return geoloc.lat.toString() + geoloc.lng.toString()
  }

  @Watch('mapEvents')
  private renderMapEvents () {
    // group events by location
    this.mapEvents.forEach(event => {
      // determine locations, wrap singletons in array
      const _geoloc = event._geoloc
      let locs: any[] = []
      if (Array.isArray(_geoloc)) {
        locs = _geoloc
      } else {
        locs = [_geoloc]
      }

      // iterate locations, store events as arrays keyed
      // by location
      locs.forEach(loc => {
        const key = this.locKey(loc)
        let entry = this.eventsByLoc.get(key)
        if (entry == null) {
          const locEvents = [event]
          this.eventsByLoc.set(key, { loc, events: locEvents })
        } else {
          // check if this event is already added, if not,
          // added
          const exists = entry.events.find(e => e.id === event.id)
          if (!exists) {
            entry.events.push(event)
          }
        }
      })
    })

    // add event markers to the map, but only
    // one per location, ordered by date
    this.eventsByLoc.forEach((entry, locKey) => {
      // sort earliest to latest
      entry.events.sort((a, b) => a.date._seconds - b.date._seconds)

      // render marker if not one for this loc already
      if(!this.renderedMarkers.has(locKey)) {
        const event = entry.events[0]
        let loc = entry.loc
        if (!Array.isArray(loc)) {
          loc = [loc]
        }
        // construct marker
        const marker = new google.maps.Marker({
          position: loc[0],
          map: this.map,
          title: event.name,
          icon: {
            url: getMapIcon(event.type),
            scaledSize: new google.maps.Size(80, 80)
          }
        })

        // click event listener
        marker.addListener('click', () => {
          if (this.currentInfoWindow != null) {
            this.currentInfoWindow.close()
          }
          
          let comp = new MapEventInfoWindow({
            propsData: {
              events: entry.events
            },
            router: this.$router
          })
          comp.$mount()
          let content = comp.$el

          const infowindow = new google.maps.InfoWindow({
            content
          })

          this.currentInfoWindow = infowindow
          infowindow.open(this.map, marker)
        })

        // add to markers map
        this.renderedMarkers.set(locKey, marker)
      }
    })
  }

  @Watch('mapOrgs')
  private renderMapOrgs () {
    // render each org
    this.mapOrgs.forEach(org => {
      // determine locations, wrap singletons in array
      const allLocs = org.locations || [org.location]
      // if orgLocation is true or null/undefined (anything but false),
      // it should appear in search results
      const orgLocs = allLocs.filter(loc => loc.isOrgLocation !== false)

      const geolocs = orgLocs.map(loc => loc._geoloc)

      // render each location for the org
      geolocs.forEach((loc, locIndex) => {
        const locKey = this.locKey(loc)

        if (!this.renderedMarkers.has(locKey)) {
          const marker = new google.maps.Marker({
            position: loc,
            map: this.map,
            title: org.name,
            icon: {
              url: org.logoImageUrl,
              scaledSize: new google.maps.Size(55, 55)
            }
          })

          // click event listener
          marker.addListener('click', () => {
            if (this.currentInfoWindow != null) {
              this.currentInfoWindow.close()
            }
            
            let comp = new MapOrgInfoWindow({
              propsData: {
                org,
                locIndex
              },
              router: this.$router
            })
            comp.$mount()

            const infowindow = new google.maps.InfoWindow({
              content: comp.$el
            })

            this.currentInfoWindow = infowindow

            infowindow.open(this.map, marker)
          })

          this.renderedMarkers.set(locKey, marker)
        }
      })
    })
  }

  @Watch('position')
  private renderPosition () {
    if (this.map == null || this.position == null) {
      return
    }

    const lng = this.position.coords.longitude
    const lat = this.position.coords.latitude

    new google.maps.Marker({
      position: { lat, lng },
      map: this.map,
      title: 'Your location',
      icon: {
        url: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAMAAABhq6zVAAAAQlBMVEVMaXFCiv9Civ9Civ9Civ9Civ9Civ9Civ9Civ+Kt/9+r/9Pkv90qf9hnf9Civ9wpv9Ee/+Jtf9Gjf9/sP9Kj/9KXf+JdfukAAAACXRSTlMAGCD7088IcsuTBctUAAAAYUlEQVR4XlWOWQrAIBBDx302d73/VSu0UMxfQsgLAMSEzmGKcGRCkZylBHPyMJQmk44QIRWdVCuxlgQoRNLaoi4ILs/a9m6VszuGf4PSaX21eyD6oZ256/AHa/0L9RauOw+4XAWqGLX26QAAAABJRU5ErkJggg==',
        scaledSize: new google.maps.Size(20, 20)
      }
    })

  }

  @Watch('typeToggle')
  private onTypeChange () {
    this.clearMarkers()
    this.search()
  }
}
